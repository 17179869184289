<template>
  <div id="list-more">
    <Dropdown trigger="custom" :visible="list_more.visible" v-clickOutside="listMoreClose">
      <Icon @click="columnView" type="md-more"/>
      <DropdownMenu slot="list">
        <div class="list-more-header">
          <span class="fleft">列展示</span>
          <span class="fright main-font-color" @click="resetColumns">重置</span>
        </div>
        <Divider/>
        <div>
          <CheckboxGroup v-model="list_more.user_selected_column" @on-change="column_select_change">
            <Checkbox v-for="item in list_more.all_columns" v-if="!(item.fixed!=undefined && item.fixed)" :label="item.title"></Checkbox>
          </CheckboxGroup>
        </div>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script>
import * as columns_util from "@/utils/columnSet";

const clickOutside = {
  // 初始化指令
  bind(el, binding, vnode) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }

    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = documentHandler;
    document.addEventListener('click', documentHandler);
  },
  update() {
  },
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener('click', el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  },
}

export default {
  name: "ColumnsSet",
  props: {
    'list_more': Object,
  },
  directives: {clickOutside},
  methods: {
    column_select_change() {
      if (this.list_more.user_selected_column.length > 0) {
        this.setColumnsAll();
        this.getUserColumns();
      }
    },
    columnView() {
      this.list_more.visible = true;
    },
    resetColumns() {
      let tmp = [];
      this.list_more.user_selected_column = [];
      for (let i = 0; i < this.list_more.all_columns.length; i++) {
        if (this.list_more.base_selected_column.includes(this.list_more.all_columns[i].title) || (this.list_more.all_columns[i].fixed != undefined && this.list_more.all_columns[i].fixed)) {
          tmp.push(this.list_more.all_columns[i])
          this.list_more.user_selected_column.push(this.list_more.all_columns[i].title)
        }
      }

      this.list_more.columns = tmp;
      this.setColumnsAll();
    },
    listMoreClose() {
      this.list_more.visible = false;
    },
    getUserColumns() {
      this.list_more.columns = [];
      columns_util.getColumnsAll(this.list_more.column_key).then(response => {
        this.list_more.user_selected_column = response;
        if (this.list_more.user_selected_column.length > 0) {
          for (let i = 0; i < this.list_more.all_columns.length; i++) {
            if (this.list_more.user_selected_column.includes(this.list_more.all_columns[i].title) || (this.list_more.all_columns[i].fixed != undefined && this.list_more.all_columns[i].fixed)) {
              this.list_more.columns.push(this.list_more.all_columns[i]);
            }
          }
        } else {
          this.resetColumns()
        }
      });
    },
    setColumnsAll() {
      //存储
      columns_util.setColumnsAll(this.list_more.column_key, this.list_more.user_selected_column);
    }
  }
}
</script>

<style scoped>
#list-more {
  right: 10px;
  position: absolute;
  top: 9px;
  z-index: 9;
  cursor: pointer;
}

.list-more-header {
  width: 160px;
  font-size: 14px;
  padding: 20px
}
</style>
<style>
#list-more .ivu-checkbox-group {
  display: flex;
  flex-direction: column;
}

#list-more .ivu-checkbox {
  margin: 10px 16px 10px 20px;
}

#list-more .ivu-divider-horizontal {
  margin: 14px 0px;
}
</style>
