import request from '@/plugins/http'

export function apiSetSaleContractMainNo(param) {
    return request.post('/api/sale/setSaleContractMainNo', param);
}

export function apiSaveSaleContract(param) {
    return request.post('/api/sale/saveSaleContract', param);
}

export function apiGetSaleContractList(param) {
    return request.post('/api/sale/getSaleContractList', param);
}

export function apiValidSaleContract(param) {
    return request.post('/api/sale/validSaleContract', param);
}

export function apiInValidSaleContract(param) {
    return request.post('/api/sale/inValidSaleContract', param);
}

export function apiDeleteSaleContract(param) {
    return request.post('/api/sale/deleteSaleContract', param);
}








