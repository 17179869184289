import WellCache from "well-cache";

let well_cache = new WellCache({prefix: localStorage.getItem('user_uniq_id'), mode: 'IDB'})

let cache_key = 'columns-info';

function getColumnKey(key) {
    return cache_key + '-' + key
}

export async function getColumnsAll(key) {
    const cacheInfo = await well_cache.getSync(getColumnKey(key));

    if (cacheInfo.data != null) {
        return cacheInfo.data;
    } else {
        return []
    }
}

//更新物流供应商信息
export async function setColumnsAll(key, columnData) {
    well_cache.save(getColumnKey(key), columnData);
}


