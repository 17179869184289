<template>
  <div id="sale-return">
    <div id="panel-header">
      <span class="top-title">销售退货</span>
      <!--<Button type="primary" class="client_create" @click="create('clientFormItem')">添加退货</Button>-->
    </div>
    <!--列表筛选条件-->
    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="list_search_item" :model="formItem" inline>
          <FormItem prop="main_no">
            <i-input @on-blur="searchSubmit" v-model="formItem.main_no" placeholder="请填写退货编号" class="w-200"/>
          </FormItem>

          <FormItem prop="status">
            <Select @on-change="searchSubmit" v-model="formItem.status" placeholder="请选择退货状态" class="w-200">
              <Option v-for="item in allReturnStatus" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="pay_status">
            <Select @on-change="searchSubmit" v-model="formItem.pay_status" multiple filterable placeholder="请选择退款状态" class="w-200">
              <Option v-for="item in allPayReturnStatus" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="created_at">
            <DatePicker type="daterange" @on-change="timeChange" placement="bottom-end"
                        placeholder="请选择创建时间" v-model="formItem.created_at" class="w-200"></DatePicker>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <!--列表-->
    <div id="list" class="sale_returns">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="main_no">
          <strong @click="checkOrderInfo(row)" class="pointer">{{ row.main_no }}</strong>
        </template>
        <template slot-scope="{ row }" slot="status">
          <Tag :style="getStatusColorBg(row.status)" class="white-font-color">{{ getStatusText(row.status) }}</Tag>
        </template>
        <template slot-scope="{ row }" slot="created_at">
          <span>{{ row.created_at| formatDate }}</span>
        </template>

        <template slot-scope="{ row }" slot="pay_status">
          <span class="pointer" :style="getPayStatusColor(row.pay_status)" @click="setPayStatusFinish(row, 'list')">{{ getPayStatusText(row.pay_status) }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <Button v-show="row.status==2" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="checkOrderInfo(row)" v-if="aCheck.rightCheck('valid_sales_returns')">
            <Icon type="ios-checkmark-circle"/>
            到货
          </Button>
          <!--入库情况下显示-->
          <Button v-show="row.status==3" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="checkOrderInfo(row)" v-if="aCheck.rightCheck('valid_sales_returns')">
            <Icon type="ios-checkmark-circle"/>
            入库
          </Button>
          <Button v-show="row.status==4|| (row.status==5&&row.pay_status==3)" type="text" size="small" style="color:#19be6b" @click="checkOrderInfo(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>

          <Button type="text" class="warning-color" size="small" v-show="[1,2].includes(row.pay_status)"
                  @click="paySaleReturnOrder(row)" v-if="aCheck.rightCheck('valid_sales_returns')">
            <Icon type="ios-checkmark-circle"/>
            退款
          </Button>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>

    </div>

    <!--添加修改退货订单-->
    <Modal v-model="modal1" :title="model_title" width="960">
      <div class="modalForm">
        <Form ref="clientFormItem" :model="clientFormItem" :label-width="100" >
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="退货单号：" prop="main_no">
                <Input v-model="clientFormItem.main_no" disabled placeholder="请填写退货单号"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="客户：" prop="client_id">
                <Select v-model="clientFormItem.client_id" placeholder="选择客户">
                  <Option v-for="item in clientList" :value="item.client_id">{{ item.client_name }}
                    ({{ item.contact_person }})
                  </Option>
                </Select>
              </FormItem>

            </Col>
            <Col span="12">
              <FormItem label="物流：" prop="logistics_id">
                <Select v-model="clientFormItem.logistics_id" placeholder="请选择物流">
                  <Option v-for="item in logisticsList" :value="item.id">{{ item.client_name }}
                    ({{ item.contact_person }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="物流单号：" prop="logistics_order_id">
                <Input v-model="clientFormItem.logistics_order_id" placeholder="请填写物流单号"
                       maxlength="300"></Input>
              </FormItem>
            </Col>

          </Row>
          <Row :gutter="20">
            <Col span="24">
              <Button icon="md-add" type="primary" @click="addGoods()">添加货品</Button>
            </Col>
          </Row>
          <Row class="mt2 display-block">
            <Table :columns="table_columns_modal" :data="clientFormItem.goods_setted" show-summary
                   :summary-method="addGoodsHandleSummary">
              <template slot-scope="{ row }" slot="thumbnail_url">
                <p><img :src="row.thumbnail_url" alt="" class="preview-img" v-show="row.thumbnail_url"></p>
              </template>
              <template slot-scope="{ row, index }" slot="sale_num">
                <p>
                  <i-input type="number" v-model="row.sale_num" @on-change="priceSum(row, index)"/>
                </p>
              </template>
              <template slot-scope="{ row, index }" slot="sale_price">
                <p>
                  <i-input type="number" v-model="row.sale_price" @on-change="priceSum(row,index)"/>
                </p>
              </template>
              <template slot-scope="{ row, index }" slot="action">
                <Button type="text" size="small" @click="deleteGoodsSelected(row, index)">
                  <Icon type="ios-trash"/>
                  删除
                </Button>
              </template>
            </Table>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset(clientFormItem)">取消</Button>
        <Button type="primary" :loading="modal_loading.add_edit" @click="createBuyOrder('clientFormItem')">保存</Button>
      </div>
    </Modal>

    <!--审核-->
    <Modal v-model="checkOrderModal" :title="checkOrderModalTitle" width="960">
      <div class="modalForm">
        <Form ref="clientFormItem" :model="clientFormItem" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="8">
              <FormItem label="退货单号" prop="main_no">
                <span class="pointer data-copy" @click="copyData(clientFormItem.main_no)">{{clientFormItem.main_no}}<Icon type="ios-copy-outline"/></span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="客户信息" prop="client_id">
                <span v-for="item in clientList"
                      v-show="item.client_id==clientFormItem.client_id">{{ item.client_name }} ({{ item.contact_person }})</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="退货理由" prop="return_reason">
                <span>{{ clientFormItem.return_reason }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="8">
              <FormItem label="到货地址" prop="address_id">
                <span v-for="item in addressList"
                      v-show="item.id==clientFormItem.address_id">{{ item.name }}({{ item.phone }}) {{ item.address }}</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="物流信息" prop="logistics_id">
                <span v-for="item in logisticsList" v-show="item.id==clientFormItem.logistics_id">{{ item.client_name }} ({{ item.contact_person }})</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="物流单号" prop="logistics_order_id">
                <span>{{ clientFormItem.logistics_order_id }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="8">
              <FormItem label="关联订单" prop="sale_no">
                <span class="pointer data-copy" @click="copyData(clientFormItem.sale_no)">{{clientFormItem.sale_no}}<Icon type="ios-copy-outline"/></span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="退货金额" prop="goods_pay">
                <strong class="main-font-color">{{clientFormItem.goods_pay}}</strong>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="实际退款" prop="status">
                <strong class="main-font-color">{{clientFormItem.real_pay}}</strong>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="8">
              <FormItem label="退款状态" prop="pay_status">
                <span :style="getPayStatusColor(clientFormItem.pay_status)">{{ getPayStatusText(clientFormItem.pay_status) }}</span>
                <Button type="text" @click="setPayStatusFinish(clientFormItem)" class="status-main fs-12" v-if="clientFormItem.pay_status == 2 && ![1,5].includes(clientFormItem.status)">
                  <Icon type="ios-checkmark-circle"/>
                  设为完结状态
                </Button>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="单据状态" prop="status">
                <Tag :style="getStatusColorBg(clientFormItem.status)" class="white-font-color">{{ getStatusText(clientFormItem.status) }}</Tag>
              </FormItem>
            </Col>
          </Row>
          <Row class="display-block">
            <Card dis-hover >
              <div>
                <span v-show="clientFormItem.status!=5" style="margin:0 10px" :class="common.view_flag == 1 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(1)">退货明细</span>
                <span style="margin:0 10px" :class="common.view_flag == 2 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(2)">退款金额记录</span>
                <Divider/>
              </div>
              <div v-show="common.view_flag == 1 && clientFormItem.status!=5">
                <Table :columns="table_columns_modal" :data="clientFormItem.goods_setted" show-summary :summary-method="addGoodsHandleSummary">
                  <template slot-scope="{ row }" slot="thumbnail_url">
                    <p><img class="h-48" :src="row.thumbnail_url" alt=""></p>
                  </template>
                  <template slot-scope="{ row, index }" slot="sale_num">
                    <span>{{ row.sale_num }}</span>
                  </template>
                  <template slot-scope="{ row, index }" slot="sale_price">
                    <span>{{ row.sale_price }}</span>
                  </template>
                  <template slot-scope="{ row, index }" slot="action">
                    <span class="disabled-color fs-12">------</span>
                  </template>
                </Table>
              </div>
              <div v-show="common.view_flag == 2">
                <Table :columns="common.real_pay_columns" :data="clientFormItem.flow_info">
                  <template slot-scope="{ row, index }" slot="money">
                    <strong>{{ row.money }}</strong>
                  </template>
                </Table>
              </div>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetOrderModal(clientFormItem)">关闭</Button>
        <Button type="success" v-show="clientFormItem.status==2" :loading="modal_loading.success"
                @click="arriveSaleReturnOrder(clientFormItem)" v-if="aCheck.rightCheck('valid_sales_returns')">确认到货
        </Button>

        <Button type="primary" v-show="clientFormItem.status==3" :loading="modal_loading.success"
                @click="saleReturnOrderIn(clientFormItem)" v-if="aCheck.rightCheck('valid_sales_returns')">订单完成，入库准备
        </Button>

        <Button type="warning" v-show="[1,2].includes(clientFormItem.pay_status)"
                @click="paySaleReturnOrder(clientFormItem)" v-if="aCheck.rightCheck('valid_sales_returns')">退款
        </Button>
      </div>
    </Modal>

    <!--付款-->
    <Modal v-model="pay_return_sale.modal" title="退款操作" width="800">
      <div class="modalForm">
        <Form ref="pay_return_sale_item" :model="pay_return_sale.item" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="6"></Col>
            <Col span="12">
              <FormItem label="订单金额" prop="goods_pay">
                <strong>{{pay_return_sale.item.goods_pay}}</strong>
              </FormItem>
            </Col>
            <Col span="6"></Col>
          </Row>
          <Row :gutter="20">
            <Col span="6"></Col>
            <Col span="12">
              <FormItem label="已退金额" prop="goods_pay">
                <span class="main-font-color">{{pay_return_sale.item.real_pay}}</span>
              </FormItem>
            </Col>
            <Col span="6"></Col>
          </Row>
          <Row :gutter="20">
            <Col span="6"></Col>
            <Col span="12">
              <FormItem label="退款金额" prop="current_pay">
                <Input v-model="pay_return_sale.item.current_pay" placeholder="请填写退款金额" type="number"></Input>
              </FormItem>
            </Col>
            <Col span="6"></Col>
          </Row>
          <Row :gutter="20">
            <Col span="6"></Col>
            <Col span="12">
              <FormItem label="退款时间" prop="pay_time">
                <DatePicker :value="pay_return_sale.item.pay_time" type="datetime"
                            format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请填写退款时间(不填，默认当前时间)" style="width: 275px"
                            @on-change="getSalePayDateTime"></DatePicker>
              </FormItem>
            </Col>
            <Col span="6"></Col>
          </Row>
          <Row :gutter="20">
            <Col span="6"></Col>
            <Col span="12">
              <FormItem label="备注" prop="remark">
                <Input v-model="pay_return_sale.item.remark" placeholder="请填写备注"></Input>
              </FormItem>
            </Col>
            <Col span="6"></Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="closePaySale">关闭</Button>
        <Button type="primary" :loading="pay_return_sale.modal_loading" @click="payReturnSaleOperate(pay_return_sale.item)">确认退款</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import * as logisticsUtil from '@/utils/logistics';
import * as clients_util from '@/utils/client';
import * as address_util from '@/utils/address';
import * as copy_util from '@/utils/copy';
import NP, {minus, times} from 'number-precision';
import {apiPayReturnSaleOperate, apiSetSaleReturnPayStatusEnd} from "@/api/sale/sale";

export default {
  name: "SaleReturns",
  data() {
    return {
      common: {
        view_flag: 1,
        real_pay_columns: [
          {
            title: '流水单号',
            key: 'flow_id',
            align: 'center',
          },
          {
            title: '退款金额',
            key: 'money',
            slot: 'money',
            align: 'center',
          },
          {
            title: '退款时间',
            key: 'flow_time',
            align: 'center',
          },
          {
            title: '备注',
            key: 'desc',
            align: 'center',
          },
          {
            title: '操作人员',
            key: 'operator_name',
            align: 'center',
          },
        ]
      },
      pay_return_sale: {
        modal:false,
        modal_loading: false,
        item: {
          goods_pay: '',
          real_pay: '',
          current_pay: '',
          pay_time: '',
          remark:''
        }
      },
      formItem: {
        main_no: '',
        status: '',
        created_at: '',
        pay_status: ''
      },
      clientFormItem: {
        id: '',
        main_no: '',
        client_id: '',
        logistics_id: '',
        logistics_order_id: '',
        address_id: '',
        goods_setted: [],
        flow_id: '',
        sale_no: '',
        pay_status: '',
        real_pay: '',
      },
      model_title: '创建货品信息',
      checkOrderModalTitle: '审核订单信息',
      modal1: false,
      checkOrderModal: false,
      modal_loading: {
        success: false,
        add_edit: false,
      },
      //列表
      list: {
        page_size_key: 'sale_returns_page_size_key',
        page_size_opts: [10, 15, 20, 30],
        columns: [
          {
            title: '退货单号',
            key: 'main_no',
            slot: 'main_no',
            width: 130,
            align: 'center',
          },
          {
            title: '单号状态',
            key: 'status',
            slot: 'status',
            width: 100,
            align: 'center',
          },
          {
            title: '数量',
            key: 'goods_num',
            align: 'center',
          },
          {
            title: '退货金额',
            key: 'goods_pay',
            // width:120,
            align: 'center',
          },
          {
            title: '实退金额',
            key: 'real_pay',
            align: 'center',
          },
          {
            title: '退款状态',
            key: 'pay_status',
            slot: 'pay_status',
            align: 'center',
          },
          {
            title: '客户',
            key: 'client_name',
            align: 'center',
          },
          {
            title: '关联订单',
            key: 'sale_no',
            width: 130,
            align: 'center',
          },
          {
            title: '创建时间',
            slot: 'created_at',
            key: 'created_at',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 150,
          }
        ],
        data: [],
        total: 0,
        size: 0,
        page: 1,
      },
      listTotal: 0,
      pageSize: 0,
      file: null,
      logisticsList: [],
      clientList: [],
      table_columns_modal: [
        {
          title: '货品编号',
          align: 'center',
          key: 'part_no'
        },
        {
          title: '货品名称',
          align: 'center',
          key: 'part_name'
        },
        {
          title: '缩略图',
          key: 'thumbnail_url',
          align: 'center',
          slot: 'thumbnail_url'
        },
        {
          title: '退货数量',
          key: 'sale_num',
          align: 'center',
          slot: 'sale_num'
        },
        {
          title: '退货单价',
          key: 'sale_price',
          align: 'center',
          slot: 'sale_price'
        },
        {
          title: '金额',
          align: 'center',
          key: 'sale_money',
        },
        {
          title: '仓库库存',
          align: 'center',
          key: 'stock_all'
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          slot: 'action'
        }
      ],
      addressList: [],
      allReturnStatus: [
        {
          "id": 1,
          "name": '待审核'
        },
        {
          "id": 2,
          "name": '退货中'
        },
        {
          "id": 3,
          "name": '待入库'
        },
        {
          "id": 4,
          "name": '已完成'
        },
      ],
      allPayReturnStatus:[
        {
          "id": 1,
          "name": '待退款'
        },
        {
          "id": 2,
          "name": '有退款'
        },
        {
          "id": 3,
          "name": '退款完结'
        },
        {
          "id": 4,
          "name": '超额退款'
        },
        {
          "id": 5,
          "name": '无需退款'
        },
      ],
    };
  },
  filters: {
    formatDate(date) {
      return date.substring(0, 10);
    }
  },
  methods: {
    setPayStatusFinish(item, show_type='view') {
      if (item.pay_status != 2 || [1,5].includes(item.status)) {
        return;
      }
      this.$Modal.confirm({
        title: "订单退款金额状态完结操作",
        content: '计划退款金额为: <strong class="status-main">'+item.goods_pay.toString()
            +'</strong><br/> 实际退款金额为: <strong class="status-main">'+item.real_pay.toString()+'</strong><br/>'
            + '确定将订单状态设置为<span class="status-danger">退款完结</span>状态？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            main_no: item.main_no,
            link_id: item.id
          }
          apiSetSaleReturnPayStatusEnd(param).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              if (show_type === 'view') {
                this.checkOrderInfo(item);
              }
              this.getOrderList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    selectTap(flag) {
      this.common.view_flag = flag;
    },
    getSalePayDateTime(value, type) {
      this.pay_return_sale.item.pay_time = value;
    },
    closePaySale() {
      this.pay_return_sale.modal = false;
    },
    payReturnSaleOperate(row) {
      this.pay_return_sale.modal_loading = true;
      if (row.current_pay ==0 || row.current_pay<0) {
        this.pay_return_sale.modal_loading = false;
        this.$Message.error('收款金额不能为0或负数！');
        return false;
      }

      this.$Modal.confirm({
        title: "退款确认提示",
        content: '退款操作即将执行，请确认填写无误？当退款金额加上已退金额大于等于订单金额时，订单将自动完结！',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            link_id : row.id,
            pay_money: row.current_pay,
            pay_time: row.pay_time,
            remark: row.remark,
          }
          apiPayReturnSaleOperate(param).then(res => {
            if (res.data.err_no == 0) {
              this.pay_return_sale.modal_loading = false;
              this.$Message.success(res.data.results);
              this.pay_return_sale.modal = false;
              this.getSaleReturnOrderById(row.id);
              this.getOrderList();
            } else {
              this.pay_return_sale.modal_loading = false;
              this.$Message.error(res.data.err_msg);
            }
          }).catch( error => {
            this.pay_return_sale.modal_loading = false;
          })
        },
        onCancel: () => {
          this.pay_return_sale.modal_loading = false;
        }
      });
    },
    copyData(data) {
      copy_util.copyData(data, this.$Message);
    },
    getSaleTypeColor(index) {
      //获取不同配色
      return index == 2 ? 'color:#ff9900' : '';
    },
    getPayTypeColor(index) {
      //获取不同配色
      return index == 2 ? 'color:#ff9900' : '';
    },
    getStatusColorBg(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'background-color:#ed4014';
          break;
        case 2:
          rs = 'background-color:#19be6b';
          break;
        case 3:
          rs = 'background-color:#2db7f5';
          break;
        case 4:
          rs = 'background-color:#2775ff';
          break;
        case 5:
          rs = 'background-color:#ff9900';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'color:#ed4014';
          break;
        case 2:
          rs = 'color:#19be6b';
          break;
        case 3:
          rs = 'color:#2db7f5';
          break;
        case 4:
          rs = 'color:#2775ff';
          break;
        case 5:
          rs = 'color:#ff9900';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getPayStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '待退款';
          break;
        case 2:
          rs = '有退款';
          break;
        case 3:
          rs = '退款完结';
          break;
        case 4:
          rs = '超额退款';
          break;
        case 5:
          rs = '无需退款';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '待审核';
          break;
        case 2:
          rs = '退货中';
          break;
        case 3:
          rs = '待入库';
          break;
        case 4:
          rs = '已完成';
          break;
        case 5:
          rs = '仅退款';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getPayStatusColor(index) {
      let rs = '';
      switch (index) {
        case 2:
          rs = 'color:#ff9900';
          break;
        case 3:
        case 4:
          rs = 'color:#2775ff';
          break;
        case 1:
          rs = 'color:#ed4014';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    priceSum(row, index) {
      //求总价
      if (isNaN(row.sale_num) || isNaN(row.sale_price)) {
        this.clientFormItem.goods_setted[index].sale_money = 0;
      } else {
        //赋值
        this.clientFormItem.goods_setted[index].sale_num = row.sale_num;
        this.clientFormItem.goods_setted[index].sale_price = row.sale_price;
        this.clientFormItem.goods_setted[index].sale_money = NP.times(row.sale_num, row.sale_price);
        row.sale_money = this.clientFormItem.goods_setted[index].sale_money;
      }
    },
    addGoodsHandleSummary({columns, data}) {
      //表格数据汇总
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: '总计'
          };
          return;
        }
        const values = data.map(item => Number(item[key]));
        if (!values.every(value => isNaN(value)) && (index == 3 || index == 5)) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return NP.plus(prev, curr);
            } else {
              return prev;
            }
          }, 0);

          if (index == 3) {
            sums[key] = {
              key,
              value: '共 ' + v
            };
          } else if (index == 5) {
            sums[key] = {
              key,
              value: '共 ' + v
            };
          }

        } else {
          sums[key] = {
            key,
            value: ''
          };
        }
      });

      return sums;
    },
    // 去除已选择的货品
    deleteGoodsSelected(row, index) {
      this.clientFormItem.goods_setted.splice(index, 1);
    },
    handleReset(name) {
      //弹出框重置
      this.modal1 = false;
      this.modal_loading.add_edit = false;
    },
    create(name) {
      //创建货品信息
      this.modal_loading.add_edit = false;
      this.model_title = '创建退货单信息';
      this.modal1 = true;
      this.$refs[name].resetFields();
      this.$axios.get('/api/sale/getBuyId')
          .then((response) => {
            if (response.data.err_no == 0) {
              this.clientFormItem.main_no = response.data.results;
              this.clientFormItem.goods_setted = [];
            }
          });
    },
    getAddressList() {
      address_util.getAddressAll().then(res => {
        this.addressList = res;
      });
    },
    listEdit(row) {
      this.getSaleReturnOrderById(row.id);

      this.model_title = '修改退货订单信息';
      this.modal1 = true;
    },
    //获取退货单信息
    getSaleReturnOrderById(id) {
      this.$axios.get('/api/sale/getSaleReturnOrderById?id=' + id).then((res) => {
        if (res.data.err_no == 0) {
          let result = res.data.results;
          this.clientFormItem.id = result.id;
          this.clientFormItem.main_no = result.main_no;
          this.clientFormItem.sale_no = result.sale_no;
          this.clientFormItem.status = result.status;
          this.clientFormItem.goods_num = parseFloat(result.goods_num);
          this.clientFormItem.goods_pay = parseFloat(result.goods_pay);
          this.clientFormItem.real_pay = parseFloat(result.real_pay);
          this.clientFormItem.pay_status = result.pay_status;
          // this.clientFormItem.money = result.goods_pay(result.money);
          this.clientFormItem.client_id = result.client_id;
          this.clientFormItem.logistics_id = result.logistics_id;
          this.clientFormItem.logistics_order_id = result.logistics_order_id;
          this.clientFormItem.address_id = result.address_id;
          // this.clientFormItem.flow_id = result.flow_id;
          this.clientFormItem.return_reason = result.return_reason;

          for (let i=0; i<result.goods_setted.length; i++) {
            result.goods_setted[i].sale_money = parseFloat(result.goods_setted[i].sale_money);
            result.goods_setted[i].sale_num = parseFloat(result.goods_setted[i].sale_num);
            result.goods_setted[i].sale_price = parseFloat(result.goods_setted[i].sale_price);
            result.goods_setted[i].stock_all = parseFloat(result.goods_setted[i].stock_all);
          }
          this.clientFormItem.goods_setted = result.goods_setted;

          for (let i=0; i<result.flow_info.length; i++) {
            result.flow_info[i].money = parseFloat(result.flow_info[i].money);
          }
          this.clientFormItem.flow_info = result.flow_info;
        }
      });
    },

    //取消订单
    cancelBuyOrder(row) {
      this.$Modal.confirm({
        title: "取消退货订单信息",
        content: '确定取消退货订单信息？一旦取消订单，该订单将失效，不再有效。',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            id: row.id,
          };
          this.$axios.post('/api/sale/cancelBuyOrder', param)
              .then((response) => {
                if (response.data.err_no == 0) {
                  this.$Message.success(response.data.results);
                  this.getOrderList();
                } else {
                  this.$Message.error(response.data.err_msg);
                }
              });
        },
        onCancel: () => {
        }
      });
    },

    //退货退货
    returnBuyOrder(row) {
      this.$Modal.confirm({
        title: "退货退货信息",
        content: '确定进行退货退货？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.$axios.post('/api/sale/returnBuyOrder', {sale_id: row.id}).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getOrderList();
            } else {
              this.$Message.error(response.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },

    //创建退货订单
    createBuyOrder(name) {
      this.modal_loading.add_edit = true;

      this.$refs[name].validate((valid) => {
        if (valid) {
          //判断是否添加了退货货品
          let goods_setted = [];
          if (this.clientFormItem.goods_setted.length < 1) {
            this.$Message.error('请添加退货货品');
            this.modal_loading.add_edit = false;
            return;
          } else {
            //过滤无效数据
            for (let i = 0; i < this.clientFormItem.goods_setted.length; i++) {
              let tmp = {
                goods_id: '',
                sale_num: 0,
                sale_price: 0,
              };
              tmp.goods_id = this.clientFormItem.goods_setted[i].id;
              tmp.sale_num = this.clientFormItem.goods_setted[i].sale_num;
              tmp.sale_price = this.clientFormItem.goods_setted[i].sale_price;

              if (tmp.sale_num <= 0) {
                this.$Message.error('退货货品数量不能小于等于0');
                this.modal_loading.add_edit = false;
                return;
              }

              if (tmp.sale_price <= 0) {
                this.$Message.error('退货货品单价不能小于等于0');
                this.modal_loading.add_edit.add_edit = false;
                return;
              }

              goods_setted[i] = tmp;
            }
          }

          let param = {
            id: this.clientFormItem.id,
            main_no: this.clientFormItem.main_no,
            client_id: this.clientFormItem.client_id,
            logistics_id: this.clientFormItem.logistics_id,
            logistics_order_id: this.clientFormItem.logistics_order_id,
            address_id: this.clientFormItem.address_id,
            flow_id: this.clientFormItem.flow_id,
            goods_setted: goods_setted,
          };
          this.$axios.post('/api/sale/createBuyOrder', param)
              .then((response) => {
                if (response.data.err_no == 0) {
                  this.$Message.success(response.data.results);
                  this.getOrderList();
                  this.modal1 = false;
                } else {
                  this.$Message.error(response.data.err_msg);
                  this.modal_loading.add_edit = false;
                }

              });
        } else {
          this.modal_loading.add_edit = false;
        }
      });
    },
    searchSubmit() {
      this.list.page = 1;
      this.getOrderList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getOrderList();
    },
    getOrderList() {
      let param = {
        page: this.list.page,
        size: this.list.size,
        main_no: this.formItem.main_no,
        status: this.formItem.status,
        pay_status: this.formItem.pay_status,
        created_at: this.formItem.created_at,
      };
      this.$axios.post('/api/sale/getSaleReturnList', param).then((res) => {
        if (res.data.err_no == 0) {
          for (let i=0; i<res.data.results.list.length; i++) {
            res.data.results.list[i].goods_num = parseFloat(res.data.results.list[i].goods_num);
            res.data.results.list[i].goods_pay = parseFloat(res.data.results.list[i].goods_pay);
            res.data.results.list[i].real_pay = parseFloat(res.data.results.list[i].real_pay);
          }
          this.list.data = res.data.results.list;
          this.list.total = res.data.results.total;
          this.list.size = res.data.results.size;
        }
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getOrderList();
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getOrderList();
    },
    getLogisticsList() {
      logisticsUtil.getLogisticsAll().then(res => {
        this.logisticsList = res;
      });
    },
    getClientList() {
      clients_util.getClientsAll().then(res => {
        this.clientList = res;
      })
    },

    //打开审核页面
    checkOrderInfo(row) {
      //待审核状态时，显示标题为 审核订单信息
      if (row.status != 1) {
        //非审核状态时,标题为 查看订单信息
        this.checkOrderModalTitle = '查看订单信息';
      }

      this.getSaleReturnOrderById(row.id);

      if (row.status == 5) {
        this.common.view_flag = 2;
      } else {
        this.common.view_flag = 1;
      }

      this.checkOrderModal = true;
      this.modal_loading.success = false;

    },
    //显示订单信息
    showEditBuyOrder(clientFormItem) {
      this.listEdit(clientFormItem);
      this.checkOrderModal = false;
    },
    //退货订单审核通过
    passBuyOrder(clientFormItem) {
      this.modal_loading.success = true;

      let param = {
        id: clientFormItem.id,
      };

      this.$axios.post('/api/sale/passBuyOrder', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getOrderList();
              this.checkOrderModal = false;
            } else {
              this.$Message.error(response.data.err_msg);
            }
            this.modal_loading.success = false;
          });
    },
    //订单已经到货
    arriveSaleReturnOrder(clientFormItem) {
      this.modal_loading.success = true;

      let param = {
        id: clientFormItem.id,
      };

      this.$axios.post('/api/sale/arriveSaleReturnOrder', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getOrderList();
              this.checkOrderModal = false;
            } else {
              this.$Message.error(response.data.err_msg);
              this.modal_loading.success = false;
            }

          });
    },
    paySaleReturnOrder(row) {
        this.pay_return_sale.item.id = row.id;
        this.pay_return_sale.item.goods_pay = row.goods_pay;
        this.pay_return_sale.item.real_pay = row.real_pay;
        this.pay_return_sale.item.current_pay = NP.minus(row.goods_pay, row.real_pay);
        this.pay_return_sale.item.remark = '';
        this.pay_return_sale.item.pay_time = '';
        this.pay_return_sale.modal = true;
    },

    //退货单入库
    saleReturnOrderIn(clientFormItem) {
      this.modal_loading.success = true;

      let param = {
        return_id: clientFormItem.id,
      };

      this.$axios.post('/api/goods/saleReturnOrderIn', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getOrderList();
              this.checkOrderModal = false;
            } else {
              this.$Message.error(response.data.err_msg);
              this.modal_loading.success = false;
            }
          });
    },
    //审核弹出框重置
    handleResetOrderModal(name) {
      this.checkOrderModal = false;
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.formItem.created_at = dateRange;
      } else {
        this.formItem.created_at = "";
      }

      this.searchSubmit();
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },

  },
  mounted() {
    this.setSelectedPageSize();
    this.getOrderList();

    this.getLogisticsList();
    this.getClientList();
    this.getAddressList();
  },
  created() {
    this.aCheck.plateName = 'sale_manage';
  },

};
</script>

<style scoped>
#sale-return {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}

#list .ivu-btn-small {
  font-size: 12px !important;
}

.select-flag {
  font-size: 16px;
  font-weight: 500;
}
</style>
<style>
#sale-return .ivu-card {
  background-color: #fafafa;
}

#sale-return .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#sale-return .ivu-form-item {
  margin-bottom: 0;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

.sale_returns .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

.sale_returns .ivu-table-wrapper {
  overflow: visible;
}

.ivu-divider-horizontal {
  margin: 10px 0 !important;
}
</style>
