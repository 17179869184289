import clipboardObj from 'clipboard';

export function copyData(data, $message) {
    let clipboard = new clipboardObj('.data-copy', {
        text: function() {
            return data;
        }
    });
    clipboard.on('success', () => {
        $message.success('复制成功！');
        clipboard.destroy();
    });
    clipboard.on('error', () => {
        $message.error('复制失败，请手动选择复制！');
        clipboard.destroy();
    });
}
