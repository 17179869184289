<template>
  <div id="sale-price" class="module-body">
    <div id="panel-header">
      <span class="top-title">售价配置</span>
      <Button type="primary" class="button_create" v-if="aCheck.rightCheck('edit_sale_price')" @click="create('addEditItem')">
        添加配置
      </Button>
    </div>

    <!--列表筛选条件-->
    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="filter_bar" :model="filter_bar" inline>
          <FormItem prop="client">
            <Select @on-change="searchSubmit" v-model="filter_bar.client" placeholder="请选择销售客户" filterable class="w-200">
              <Option v-for="item in client_list" :value="item.id" :key="item.id">{{ item.client_name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="part_no">
            <Input @on-blur="searchSubmit" v-model="filter_bar.part_no" placeholder="请填写货品编号" class="w-200"/>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('filter_bar')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <!--列表-->
    <div id="list" class="price_list">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="part_no">
          <strong class="pointer">{{ row.part_no }}</strong>
        </template>
        <template slot-scope="{ row }" slot="thumbnail_url">
          <p class="pointer"><img :src="row.thumbnail_url" alt="" class="preview-img" v-show="row.thumbnail_url"></p>
        </template>
        <template slot-scope="{ row }" slot="status">
          <span :style="getStatusColor(row.status)">{{ getStatusText(row.status) }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <!--待审核情况下显示-->
          <Button v-show="row.status==1" type="text" size="small" class="warning-color"
                  @click="invalidSalePriceStatus(row)" v-if="aCheck.rightCheck('valid_sale_price')">
            <Icon type="ios-close-circle"/>
            失效
          </Button>
          <!--审核通过情况下显示-->
          <Button v-show="row.status==0" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="validSalePriceStatus(row)" v-if="aCheck.rightCheck('valid_sale_price')">
            <Icon type="ios-checkmark-circle"/>
            生效
          </Button>
          <Dropdown v-show="row.status==0">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem v-show="row.status==0" v-if="aCheck.rightCheck('edit_sale_price')">
                <span @click="listUpdateInfo(row)" class="f12">修改</span>
              </DropdownItem>
              <DropdownItem v-show="row.status==0" v-if="aCheck.rightCheck('edit_sale_price')">
                <span @click="listDeleteAlert(row)" class="f12">删除</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>
    </div>

    <!--添加修改-->
    <Modal v-model="add_edit.modal" :title="add_edit.model_title" width="960">
      <div class="modalForm">
        <Form ref="addEditItem" :model="add_edit.item" :label-width="100" :rules="ruleInline" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="客户" prop="client_id">
                <Select v-model="add_edit.item.client_id" placeholder="选择客户">
                  <Option v-for="item in client_list" :value="item.id">{{ item.client_name }}
                    ({{ item.contact_person }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row class="mt2 display-block">
            <Card :bordered="false">
              <p slot="title">货品明细</p>
              <Button type="primary" slot="extra" size="small" @click="addGoods()" class="f12">
                <Icon type="md-add"/>
                添加货品
              </Button>
              <Table :columns="add_edit.product_columns" :data="add_edit.item.goods_setted">
                <template slot-scope="{ row }" slot="thumbnail_url">
                  <p><img :src="row.thumbnail_url" alt="" class="preview-img" v-show="row.thumbnail_url"></p>
                </template>
                <template slot-scope="{ row, index }" slot="sale_price">
                  <p>
                    <i-input type="number" v-model="row.sale_price" @on-change="setPrice(row, index)"/>
                  </p>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <Button type="text" size="small" class="fs-12" @click="deleteGoodsSelected(row, index)">
                    <Icon type="ios-trash"/>
                    删除
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset(add_edit.item)">取消</Button>
        <Button type="primary" :loading="add_edit.modal_loading" @click="createSalePrice('addEditItem')">保存</Button>
      </div>
    </Modal>

    <!--修改销售价格-->
    <Modal v-model="list_update.modal" :title="list_update.model_title" width="960">
      <div class="modalForm">
        <Form ref="listUpdateItem" :model="list_update.item" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="货品编号">
                <span>{{ list_update.item.part_no }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="货品名称">
                <span>{{ list_update.item.part_name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="缩略图">
                <img v-if="list_update.item.thumbnail_url" :src="list_update.item.thumbnail_url" style="width:100%;height:100%">
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="客户">
                <span>{{ list_update.item.client_name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="售价" prop="sale_price">
                <Input v-model="list_update.item.sale_price" placeholder="请填写货品售价" type="number"></Input>
              </FormItem>
            </Col>
            <Col span="12">

            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="priceHandleReset">取消</Button>
        <Button type="primary" :loading="list_update.modal_loading" @click="updateSalePrice">保存
        </Button>
      </div>
    </Modal>

    <SelectGoods ref="select_goods" @selected="getSelectedGoods" :select_goods="select_goods"/>
  </div>
</template>

<script>
import '@/css/common.css';
import SelectGoods from '@/components/common/SelectGoods.vue';

import {
  getClientList,
  createSalePrice,
  getClientSalePriceList,
  invalidSalePriceStatus,
  validSalePriceStatus,
  updateSalePrice,
  deleteClientSalePrice
} from "@/api/sale/sale";

export default {
  name: "SalePrice",
  data() {
    return {
      select_goods: {
        modal: false,
        count_flag: 0,
        goods_type: 2
      },
      filter_bar: {
        part_no: '',
        client: '',
      },
      client_list: [],
      list: {
        page_size_key: 'sale_price_page_size_key',
        page_size_opts: [10, 15, 20, 30],
        columns: [
          {
            title: '货品编号',
            key: 'part_no',
            slot: 'part_no',
            align: 'center',
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center',
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center',
          },
          {
            title: '客户',
            key: 'client_name',
            align: 'center',
          },
          {
            title: '售价',
            key: 'sale_price',
            align: 'center',
          },
          {
            title: '状态',
            key: 'status',
            slot: 'status',
            align: 'center',
          },
          {
            title: '操作',
            slot: 'action',
            key: 'action',
            align: 'center',
            minWidth: 60,
          }
        ],
        data: [],
        total: 0,
        size: 0,
        page: 1,
      },
      add_edit: {
        modal: false,
        modal_loading: false,
        model_title: '售价批量配置',
        product_columns: [
          {
            title: '货品编号',
            key: 'part_no',
            align: 'center',
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center',
          },
          {
            title: '缩略图',
            key: 'thumbnail_url',
            slot: 'thumbnail_url',
            align: 'center',
          },
          {
            title: '销售单价',
            key: 'sale_price',
            slot: 'sale_price',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            slot: 'action'
          }
        ],
        item: {
          sale_no: '',
          sale_type: '',
          client_id: '',
          goods_setted: [],
        },
      },
      list_update: {
        modal: false,
        modal_loading: false,
        model_title: '售价批量配置',
        item: {},
      },
      ruleInline: {
        client_id: [
          {required: true, message: '请选择客户'},
        ],
      },
    };
  },
  methods: {
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getClientSalePriceList();
    },
    searchSubmit() {
      this.list.page = 1;
      this.getClientSalePriceList();
    },
    listDeleteAlert(row) {
      let param = {
        id: row.id,
      };

      this.$Modal.confirm({
        title: "价格配置删除",
        content: '确定要将该价格配置删除？删除后，填写订单式时，对应货品的售价将显示默认价格',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          deleteClientSalePrice(param).then((res) => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getClientSalePriceList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    listUpdateInfo(row) {
      this.list_update.model_title = '修改货品销售价';
      this.list_update.item = row;
      this.list_update.modal = true;
      this.list_update.modal_loading = false;
    },
    updateSalePrice() {
      this.list_update.modal_loading = true;
      let param = {
        id: this.list_update.item.id,
        price: this.list_update.item.sale_price,
      }

      updateSalePrice(param).then((res) => {
        this.list_update.modal_loading = false;
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.list_update.modal = false;
          this.getClientSalePriceList();
        } else {
          this.$Message.error(res.data.err_msg);
        }
      });
    },
    getStatusColor(index) {
      let rs = '';
      switch (parseInt(index)) {
        case 0:
          rs = 'color:#ed4014';
          break;
        case 1:
          rs = 'color:#19be6b';
          break;
      }

      return rs;
    },
    getStatusText(index) {
      let rs = '';
      switch (parseInt(index)) {
        case 0:
          rs = '待生效';
          break;
        case 1:
          rs = '已生效';
          break;
      }

      return rs;
    },
    create(name) {
      this.add_edit.modal = true;
      this.add_edit.modal_loading = false;
      this.$refs[name].resetFields();

      this.add_edit.item.goods_setted = [];
    },
    getClientList() {
      //获取客户列表
      getClientList().then((res) => {
        if (res.data.err_no === 0 && res.data.results.list !== undefined) {
          this.client_list = res.data.results.list;
        }
      });
    },
    //添加货品
    addGoods() {
      this.$refs.select_goods.select_goods.goods_type = this.select_goods.goods_type;
      this.$refs.select_goods.showGoodsList();
    },
    getSelectedGoods(val) {
      //获取选中的货品
      for (let i = 0; i < val.length; i++) {
        let added_flag = false;

        //排除已添加的
        for (let j = 0; j < this.add_edit.item.goods_setted.length; j++) {
          if (this.add_edit.item.goods_setted[j].id == val[i].id) {
            added_flag = true;
            break;
          }
        }

        //如果未添加
        if (!added_flag) {
          let tmp = {};
          tmp.id = val[i].id;
          tmp.sale_price = val[i].selling_price;
          tmp.part_no = val[i].part_no;
          tmp.part_name = val[i].part_name;
          tmp.thumbnail_url = val[i].thumbnail_url;
          this.add_edit.item.goods_setted.push(tmp);
        }
      }
    },
    setPrice(row, index) {
      //赋值
      this.add_edit.item.goods_setted[index].sale_price = row.sale_price;
    },
    // 去除已选择的货品
    deleteGoodsSelected(row, index) {
      this.add_edit.item.goods_setted.splice(index, 1);
    },
    handleReset(name) {
      //弹出框重置
      this.add_edit.modal = false;
      this.add_edit.modal_loading = false;
    },
    priceHandleReset() {
      //弹出框重置
      this.list_update.modal = false;
      this.list_update.modal_loading = false;
    },
    //创建销售订单
    createSalePrice(name) {
      this.add_edit.modal_loading = true;
      let info = this.add_edit.item;

      this.$refs[name].validate((valid) => {
        if (valid) {
          //判断是否添加了销售货品
          let goods_setted = [];

          if (info.goods_setted.length < 1) {
            this.$Message.error('请添加销售货品');
            this.add_edit.modal_loading = false;
            return;
          }

          //过滤无效数据
          for (let i = 0; i < info.goods_setted.length; i++) {
            let tmp = {};
            tmp.goods_id = info.goods_setted[i].id;
            tmp.sale_price = info.goods_setted[i].sale_price;

            if (tmp.sale_price <= 0) {
              this.$Message.error('货品单价不能小于等于0');
              this.add_edit.modal_loading = false;
              return;
            }
            goods_setted[i] = tmp;
          }


          let param = new FormData(); //创建form对象

          param.append('client_id', info.client_id);
          param.append('goods_setted', JSON.stringify(goods_setted));

          createSalePrice(param).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getClientSalePriceList();
              this.add_edit.modal = false;
            } else {
              this.$Message.error(response.data.err_msg);
              this.add_edit.modal_loading = false;
            }
          }).catch((error) => {
            this.$Message.error('保存失败');
            this.add_edit.modal_loading = false;
          });
        } else {
          this.add_edit.modal_loading = false;
        }
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getClientSalePriceList();
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getClientSalePriceList();
    },
    //获取列表数据
    getClientSalePriceList() {
      let param = {
        params: {
          page: this.list.page,
          page_size: this.list.size,
          part_no: this.filter_bar.part_no,
          client_id: this.filter_bar.client,
        }
      };
      getClientSalePriceList(param).then((response) => {
        if (response.data.err_no == 0) {
          this.list.data = response.data.results.list;
          this.list.total = response.data.results.total;
          this.list.size = response.data.results.size;
        }
      });
    },
    invalidSalePriceStatus(row) {
      let param = {
        id: row.id,
      };

      this.$Modal.confirm({
        title: "账号失效",
        content: '确定要将该价格配置失效？失效后，填写订单式时，对应货品的售价将显示默认价格',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          invalidSalePriceStatus(param).then((res) => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getClientSalePriceList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    validSalePriceStatus(row) {
      let param = {
        id: row.id,
      }

      validSalePriceStatus(param).then((res) => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.getClientSalePriceList();
        } else {
          this.$Message.error(res.data.err_msg);
        }
      });
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  created() {
    this.aCheck.plateName = 'sale_manage';
  },
  mounted() {
    this.setSelectedPageSize();
    this.getClientSalePriceList();

    this.getClientList();
  },
  components: {
    SelectGoods,
  }
}
</script>

<style scoped>
#panel-header {
  position: relative;
  border: none;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

.button_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#sale-price .ivu-card {
  background-color: #fafafa;
}

#sale-price .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#sale-price .ivu-form-item {
  margin-bottom: 0;
}

#sale-price .ivu-btn-small {
  font-size: 12px !important;
}


#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}
</style>

<style>
.price_list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

.price_list .ivu-table-wrapper {
  overflow: visible;
}

#sale-price .ivu-form-item {
  margin-bottom: 0;
}

</style>
