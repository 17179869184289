<template>
  <div>
    <Upload type="drag"
            :action="upload_file.upload_host"
            :data="upload_file.file_data"
            :headers="upload_file.headers"
            :max-size="204800"
            :on-exceeded-size="handleMaxSize"
            :on-success="uploadSuccess"
            :before-upload="handleBeforeUpload"
            :on-remove="handleRemoveOperate"
            :default-file-list="default_file_list"
            ref="upload_file">
      <div style="padding: 20px 0">
        <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
        <p>点击或者拖拽文件上传</p>
      </div>
    </Upload>
  </div>
</template>

<script>
import {oss} from "@/plugins/oss";

export default {
  name: "FileUpload",
  data() {
    return {
      upload_file: {
        headers: {
          Authorization: '',
          AuthType: 1//1表示网站
        },
        file_data: {},
        upload_host: '',
        uploaded: [],
      },
      default_file_list:[]
    }
  },
  props: {
    'set_param': Object,
  },
  mounted() {
    let param = {
      type: this.set_param.type,//1表示货品文件上传, 4表示合同文件上次
      label: 'contract',
    };
    this.setOssSignature(param);
  },
  methods: {
    handleRemoveOperate(file, fileList) {
      for (let i = 0; i < this.upload_file.uploaded.length; i++) {
        if (this.upload_file.uploaded[i].file_name == file.name) {
          this.upload_file.uploaded.splice(i, 1)
        }
      }
      this.$emit('uploaded', this.upload_file.uploaded);
    },
    handleBeforeUpload(file) {
      let left_file_capacity = localStorage.getItem('left_file_capacity');

      const check = this.$refs.upload_file.fileList.length < this.set_param.limit;
      const capacity_check = parseFloat(left_file_capacity) > 0;

      if (!check) {
        this.$Notice.warning({
          title: '最多支持'+this.set_param.limit.toString()+'个文件'
        });
      }

      if (!capacity_check) {
        this.$Notice.warning({
          title: '账号存储容量已满，请联系客服！'
        });
      }

      if (check && capacity_check) {
        return new Promise((resolve, reject) => {
          let time_now = new Date().getTime();
          //加前缀防止相同名称文件替换了
          let fileName = this.set_param.main_no + time_now.toString() + file.name;
          let info = oss.ossInfo(fileName, 'contract');
          this.upload_file.upload_host = info.host;
          this.upload_file.file_data = info;
          resolve(file);
        });
      }
      return check && capacity_check;
    },
    setOssSignature(param) {
      if (oss.obj['contract'] == undefined) {
        oss.getSignature(param);
      } else {
        if (oss.obj['contract'].expire < parseInt(new Date().getTime().toString().substr(0, 10))) {
          oss.getSignature(param);
        }
      }
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: '上传文件过大',
        desc: '上传  ' + file.name + ' 太大, 请不要超过 200M.'
      });
    },
    uploadSuccess(res, file, fileList) {
      if (res.err_no == -1) {
        this.$Message.error(res.err_msg);
        this.$refs.upload_file.fileList = [];
      } else {
        this.upload_file.uploaded = [];
        for (let i=0; i<fileList.length; i++) {
          let tmp = {
            file_url: fileList[i].response!=undefined ? fileList[i].response.results.url : fileList[i].url,
            file_name: fileList[i].name||'',
            file_size: fileList[i].size,
          }
          this.upload_file.uploaded.push(tmp);
        }

        this.$emit('uploaded', this.upload_file.uploaded);
      }
    },
  }
}
</script>

<style scoped>

</style>
