<template>
  <div id="sale-num" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">货品销量</span>
    </div>

    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="list_search_item" :model="list.search_item" inline>
          <FormItem prop="main_no">
            <Input @on-blur="getMainList()" v-model="list.search_item.main_no" class="w-200" placeholder="请填写货品号"/>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <div id="list" class="main-list">
      <Table :columns="list.columns" :data="list.data">

        <template slot-scope="{ row }" slot="part_no">
          <strong>{{row.part_no}}</strong>
        </template>

        <template slot-scope="{ row }" slot="updated_at">
          <span>{{ row.updated_at.substring(0, 16) }}</span>
        </template>
      </Table>

      <Row class="mt-16">
        <Col span="6">
          <span class="fs12 disabled-color"><Icon type="ios-alert-outline"/> 销量数据每天凌晨定时更新一次</span>
        </Col>
        <Col span="18" class="page">
          <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
                :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
        </Col>
      </Row>
    </div>

  </div>
</template>

<script>
import {
  getSaleNumList,
} from '@/api/sale/saleNum';

export default {
  name: "SaleNum",
  data() {
    return {
      list: {
        page_size_key: 'sale_num_page_size_key',
        page_size_opts: [10, 15, 20, 30],
        size:0,
        page:1,
        search_item: {
          main_no: '',
        },
        columns: [
          {
            title: '货品号',
            key: 'part_no',
            slot: 'part_no',
            align: 'center',
          },
          {
            title: '货品名称',
            key: 'part_name',
            align: 'center',
          },
          {
            title: '总销量',
            key: 'sale_num',
            align: 'center',
          },
          {
            title: '当月销量',
            key: 'month_sale_num',
            align: 'center',
          },
          {
            title: '更新时间',
            key: 'updated_at',
            slot: 'updated_at',
            align: 'center',
          },
        ],
        data: []
      },
    }
  },
  methods: {
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.getMainList();
    },
    getMainList() {
      let param = {
        page: this.list.page,
        page_size: this.list.size,
        main_no: this.list.search_item.main_no,
      };

      getSaleNumList(param).then((res) => {
        this.list.data = res.data.results.list;
        this.list.total = res.data.results.total;
        this.list.size = res.data.results.size;
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getMainList();
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
  },
  mounted() {
    this.setSelectedPageSize();
    this.getMainList();
  }
}
</script>

<style scoped>
#sale-num .ivu-card {
  background-color: #fafafa;
}

#sale-num .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}
</style>
<style>
#sale-num .ivu-form-item {
  margin-bottom: 0;
}
</style>
