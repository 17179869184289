<template>
  <div id="sale-contract" class="module-body">
    <div id="panel-header">
      <span class="top-title">销售合同</span>
      <Button type="primary"   @click="createOperate('operateItem')" v-if="aCheck.rightCheck('edit_sale_contract')">
        添加
      </Button>
    </div>

    <!--列表筛选条件-->
    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="search"  inline>
          <FormItem prop="main_no">
            <Input @on-blur="searchSubmit" v-model="search.main_no" placeholder="请填写合同编号" class="w-200"/>
          </FormItem>

          <FormItem prop="link_project">
            <Select v-model="search.link_project" filterable placeholder="请选择所属项目" @on-change="searchSubmit">
              <Option v-for="item in common.project_list" :value="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="link_client">
            <Select v-model="search.link_client" placeholder="选择客户" filterable @on-change="searchSubmit">
              <Option v-for="item in common.client_list" :value="item.client_id">{{item.client_name}}({{ item.contact_person }})
              </Option>
            </Select>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <!--列表-->
    <div id="list" class="price_list">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="main_no">
          <strong class="pointer" @click="showContract(row)">{{ row.main_no }}</strong>
        </template>

        <template slot-scope="{ row }" slot="start_date">
          <span>{{ row.start_date.substring(0,10) }} <span v-show="row.end_date">~</span> {{ row.end_date.substring(0,10) }}</span>
        </template>

        <template slot-scope="{ row }" slot="status">
          <span :class="row.status ==1? 'status-danger fs-12':'status-success fs-12'">{{ row.status ==1 ? '待生效' : '已生效' }}</span>
        </template>

        <template slot-scope="{ row }" slot="link_project">
          <span v-for="item in common.project_list" v-if="row.link_project == item.id">{{ item.name }}</span>
        </template>

        <template slot-scope="{ row }" slot="link_client">
          <span v-for="item in common.client_list" v-if="row.link_client == item.client_id">{{ item.client_name }}</span>
        </template>

        <template slot-scope="{ row }" slot="action">
          <Button v-show="[1].includes(row.status)" type="text" size="small" class="status-danger" @click="validSaleContract(row)" v-if="aCheck.rightCheck('valid_sale_contract')">
            <Icon type="ios-checkmark-circle"/>
            生效
          </Button>
          <Button v-show="[2].includes(row.status)" type="text" size="small" class="status-success" @click="showContract(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>
          <Dropdown v-show="[1].includes(row.status)" v-if="aCheck.rightCheck('edit_sale_contract')">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem>
                <span @click="listEdit(row)" class="f12">修改</span>
              </DropdownItem>
              <DropdownItem>
                <span @click="listDeleteAlert(row)" class="f12">删除</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
    </div>

    <!--添加修改-->
    <Modal v-model="operate.modal" :title="operate.modal_title" width="960">
      <Form ref="operateItem" :model="operate.item" :label-width="100" :rules="operate.ruleInline" label-colon>
        <Row :gutter="10">
          <Col span="12">
            <FormItem label="合同编号" prop="main_no">
              <Input v-model="operate.item.main_no" @on-change="setMainNO" placeholder="请填写合同编号" search enter-button="系统生成" @on-search="setContractMainNo"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="合同名称" prop="name">
              <Input v-model="operate.item.name" placeholder="请填写合同名称"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="12">
            <FormItem label="所属项目" prop="link_project">
              <Select v-model="operate.item.link_project" filterable placeholder="请选择所属项目" @on-change="getBindClient(operate.item.link_project)">
                <Option value="0">暂无项目</Option>
                <Option v-for="item in common.project_list" :value="item.id">{{ item.name }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="关联客户" prop="link_client">
              <Select v-model="operate.item.link_client" placeholder="选择客户" filterable>
                <Option v-for="item in common.client_list" :value="item.client_id">{{item.client_name}}({{ item.contact_person }})
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="12">
            <FormItem label="合同金额" prop="money">
              <Input v-model="operate.item.money" placeholder="请填写合同金额" type="number"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="起止日期" prop="start_end_date">
              <DatePicker type="daterange" @on-change="timeChange" placement="bottom-end" placeholder="请选择合同起止日期" style="width:356px;" :value="operate.item.start_end_date"></DatePicker>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="12">
            <FormItem label="交付日期" prop="delivery_time">
              <DatePicker :value="operate.item.delivery_time" type="datetime" format="yyyy-MM-dd HH:mm:ss" placeholder="请填写交付日期" style="width:356px;"  @on-change="setDeliveryTime"></DatePicker>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="备注" prop="remark">
              <Input v-model="operate.item.remark" placeholder="请填写备注"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="12">
            <FormItem label="合同附件" prop="attachment">
              <FileUpload ref="contract_file_upload" @uploaded="getUploadFiles" :set_param="upload_set_param"/>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div slot="footer">
        <Button @click="closeOperateModal('operateItem')">关闭</Button>
        <Button type="primary" :loading="operate.modal_loading" @click="createSaleContract('operateItem')">保存</Button>
      </div>
    </Modal>

    <!--查看-->
    <Modal v-model="view.modal" title="查看合同详情" width="960">
      <Form :label-width="100"  label-colon>
        <Row :gutter="10">
          <Col span="12">
            <FormItem label="合同编号">
              <span>{{view.item.main_no}}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="合同名称" prop="name">
              <span>{{view.item.name}}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="12">
            <FormItem label="所属项目" >
              <span v-for="item in common.project_list" v-if="view.item.link_project == item.id">{{ item.name }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="关联客户">
              <span v-for="item in common.client_list" v-if="view.item.link_client == item.client_id">{{ item.client_name }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="12">
            <FormItem label="合同金额" prop="money">
              <strong class="main-font-color">{{view.item.money}}</strong>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="起止日期" prop="start_end_date">
              <span>{{view.item.start_date}} <span v-show="view.item.end_date">~</span> {{view.item.end_date}}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="12">
            <FormItem label="交付日期">
              <span>{{view.item.delivery_time}}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="备注">
              <span>{{view.item.remark}}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="10">
          <Col span="12">
            <FormItem label="状态">
              <span :class="view.item.status ==1?'status-danger':'status-success'">{{view.item.status ==1 ?'待生效':'已生效'}}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="合同附件" prop="attachment">
              <div v-for="item in view.item.attachment" class="mb-6">
                <span>{{item.file_name}}</span>
                <a :href="item.file_url" :download="item.file_url" target="_blank">
                  <Icon type="ios-download"/>
                </a>
              </div>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div slot="footer">
        <Button @click="closeViewModal">关闭</Button>
        <Button type="warning" v-show="view.item.status ==2" v-if="aCheck.rightCheck('valid_sale_contract')" :loading="view.modal_loading" @click="inValidSaleContract">失效</Button>
        <Button type="primary" v-show="view.item.status ==1" v-if="aCheck.rightCheck('edit_sale_contract')" :loading="view.modal_loading" @click="listEdit(view.item)">修改</Button>
      </div>
    </Modal>

  </div>
</template>

<script>
import {apiSetSaleContractMainNo, apiSaveSaleContract, apiGetSaleContractList,
  apiValidSaleContract, apiInValidSaleContract, apiDeleteSaleContract} from "@/api/sale/saleContract";
import FileUpload from '@/components/common/FileUpload.vue';
import * as project_util from "@/utils/setting/project";
import * as clientsUtil from "@/utils/client";

export default {
  name: "SaleContract",
  components: {
    FileUpload,
  },
  data() {
    return {
      upload_set_param: {
        main_no: '',
        limit: 3,
        type: 4,
        file_data:[]
      },
      view: {
        modal: false,
        modal_loading: false,
        item: {
          id: 0,
          main_no: '',
          name: '',
          start_date:'',
          end_date:'',
          attachment:[],
          link_project: '',
          link_client: '',
          money: '',
          remark:'',
          delivery_time: '',
        }
      },
      operate: {
        modal: false,
        modal_loading: false,
        modal_title: '编辑合同信息',
        ruleInline: {
          main_no: [
            {required: true, message: '请填写合同编号'},
          ],
          name: [
            {required: true, message: '请填写合同名称'},
          ],
          link_client: [
            {required: true, message: '请选择关联客户'},
          ],
          money: [
            {required: true, message: '请填写合同金额'},
          ],
          start_end_date: [
            {required: true, message: '请选择合同起止日期'},
          ],
        },
        item: {
          id: 0,
          main_no: '',
          name: '',
          start_end_date:[],
          attachment:[],
          link_project: '',
          link_client: '',
          money: '',
          remark:'',
          delivery_time: '',
        }
      },

      search: {
        main_no: '',
        link_project: '',
        link_client: '',
      },
      list:{
        columns: [
          {
            title: '合同编号',
            key: 'main_no',
            slot: 'main_no',
            width: 200,
            align: 'center',
          },
          {
            title: '合同名称',
            key: 'name',
            align: 'center',
          },
          {
            title: '所属项目',
            key: 'link_project',
            slot: 'link_project',
            align: 'center',
          },
          {
            title: '关联客户',
            key: 'link_client',
            slot: 'link_client',
            align: 'center',
          },
          {
            title: '合同金额',
            key: 'money',
            align: 'center',
          },
          {
            title: '起止日期',
            key: 'start_date',
            slot: 'start_date',
            width: 200,
            align: 'center',
          },
          {
            title: '状态',
            key: 'status',
            slot: 'status',
            align: 'center',
          },
          {
            title: '操作',
            slot: 'action',
            key: 'action',
            align: 'center',
            minWidth: 60,
          }
        ],
        data:[],
        total: 0,
        size: 0,
      },
      common: {
        project_list: [],
        client_list: [],
      }
    }
  },
  mounted() {
    this.getProjectList();
    this.getClientList();

    this.getSaleContractList();
  },
  created() {
    this.aCheck.plateName = 'sale_manage';
  },
  methods:{
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除销售合同信息",
        content: '确定删除销售合同信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
           id: row.id,
           main_no: row.main_no,
          }
           apiDeleteSaleContract(param).then(res => {
             if (res.data.err_no == 0) {
               this.$Message.success(res.data.results);
               this.getSaleContractList();
             }
           });
        },
        onCancel: () => {
        }
      });
    },
    listEdit(row) {
      this.view.modal = false;
      this.operate.item = row;
      this.operate.item.start_end_date = [row.start_date, row.end_date];
      this.$refs.contract_file_upload.default_file_list = [];
      for (let i=0; i<row.attachment.length; i++) {
        let tmp = {
          name: row.attachment[i].file_name,
          url: row.attachment[i].file_url,
          size: row.attachment[i].file_size
        }
        this.$refs.contract_file_upload.default_file_list.push(tmp);
      }
      this.operate.modal = true;
    },
    inValidSaleContract() {
      this.view.modal_loading = true;
      let param = {
        id: this.view.item.id,
        main_no: this.view.item.main_no,
      }
      apiInValidSaleContract(param).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success('失效成功');
          this.view.item.status = 1;
          this.getSaleContractList();
        }

        this.view.modal_loading = false;
      });
    },
    closeViewModal() {
      this.view.modal = false;
    },
    showContract(row) {
      this.view.item = row;
      this.view.modal = true;
    },
    validSaleContract(row) {
      let param = {
        id: row.id,
        main_no: row.main_no,
      }
      apiValidSaleContract(param).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success('生效成功');

          this.getSaleContractList();
        }
      });
    },
    getSaleContractList() {
      let param = {
        page: this.list.page,
        size: this.list.size,
        main_no: this.search.main_no,
        link_client: this.search.link_client,
        link_project: this.search.link_project,
      }
      apiGetSaleContractList(param).then(res => {
        if (res.data.err_no == 0) {
          for (let i=0; i<res.data.results.list.length; i++) {
            res.data.results.list[i].money = parseFloat(res.data.results.list[i].money);
            res.data.results.list[i].attachment = res.data.results.list[i].attachment !='' ? JSON.parse(res.data.results.list[i].attachment):[];
          }
          this.list.data = res.data.results.list;
          this.list.total = res.data.results.total;
          this.list.size = res.data.results.size;
        }
      });
    },
    createSaleContract(name) {
      this.operate.modal_loading = true;
      this.$refs[name].validate((valid) => {
        if (valid) {
          apiSaveSaleContract(this.operate.item).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.operate.modal = false;
              this.getSaleContractList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          })
        }

        this.operate.modal_loading = false;
      });
    },
    closeOperateModal(name) {
      //弹出框重置
      this.operate.modal = false;
    },
    cancelSearch() {
      this.search.main_no = '';
      this.search.link_client = '';
      this.search.link_project = '';

      this.list.page = 1;
      this.getSaleContractList();
    },
    setDeliveryTime(value) {
      this.operate.item.delivery_time = value;
    },
    getClientList() {
      clientsUtil.getClientsAll().then(res => {
        this.common.client_list = res;
      })
    },
    getBindClient(project_id) {
      if (project_id == 0) {
        return;
      }
      this.operate.item.link_client = '';
      for (let i=0; i< this.common.project_list.length; i++) {
        if (this.common.project_list[i].id == project_id) {
          this.operate.item.link_client = this.common.project_list[i].client_id;
        }
      }
    },
    getProjectList() {
      project_util.getProjectAll().then(res => {
        this.common.project_list = res;
      });
    },
    setMainNO() {
      this.upload_set_param.main_no = this.operate.item.main_no;
    },
    getUploadFiles(val) {
      this.operate.item.attachment = val;
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.operate.item.start_end_date = dateRange;
      } else {
        this.operate.item.start_end_date = [];
      }
    },
    searchSubmit() {
      this.list.page = 1;
      this.getSaleContractList();
    },
    createOperate(name) {
      this.$refs[name].resetFields();
      //弹出框重置
      this.operate.modal = true;
      this.operate.modal_loading = false;
    },
    setContractMainNo() {
      apiSetSaleContractMainNo().then(res => {
          if (res.data.err_no == 0) {
            this.operate.item.main_no = res.data.results;
            this.setMainNO();
          }
      });
    },
  }
}
</script>

<style scoped>
#panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}


#sale-contract .ivu-card {
  background-color: #fafafa;
}

#sale-contract .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#sale-contract .ivu-form-item {
  margin-bottom: 0;
}

#sale-contract .ivu-btn-small {
  font-size: 12px !important;
}


#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}
</style>

<style>
.price_list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

.price_list .ivu-table-wrapper {
  overflow: visible;
}

#sale-contract .ivu-form-item {
  margin-bottom: 0;
}

</style>
